import React, { useEffect, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import CreatableSelect from "react-select/creatable";
import ArrayObj from "../../../Constant/arrayObj";
import "./cricketleage.css";

const CricketTeamForm = () => {
  const initialPlayers = Array.from({ length: 15 }, () => ({
    name: "",
    dob: "",
    age: "",
    position: "",
    arm: "",
    id_proof: "",
    health: "",
    tshirtSize: "",
    pentSize: "",
  }));
  const [players, setPlayers] = useState(initialPlayers);
  const [schoolName, setSchoolName] = useState("");
  const fileInputRef = useRef(null);

  const options = ArrayObj.schoolList.map((item) => ({
    value: item.name.toLowerCase(),
    label: item.name,
    other: item.is_other,
  }));

  const HandleAddSchool = (name) => {
    if (name) {
      setSchoolName(name.value);
    }
  };

  const handleLabelClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedPlayers = [...players];
    updatedPlayers[index][field] = value;
    if (field === "dob") {
      const birthDate = new Date(value);
      const userDate = new Date();
      let age = userDate.getFullYear() - birthDate.getFullYear();
      updatedPlayers[index].age = age;
    }
    setPlayers(updatedPlayers);
  };

  const handleSave = () => {
    console.log("players", players);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      >
        Register Your Team
      </button>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable position-relative">
          <div className="modal-content cricket_modal">
            <div
              className="close-btn cricket-btn  position-absolute"
              style={{ cursor: "pointer" }}
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <IoMdClose className="close-modal" />
            </div>

            <div className="w-100">
              <div className="form-section page-section">
                <div className="form-input-wide" data-type="control_head">
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <div
                      className="form-header-group hasImage header-large w-100"
                      data-imagealign="Top"
                    >
                      <div className="header-logo w-100 overflow-hidden">
                        <img
                          src={require("../../../assetes/img/cricket-header.png")}
                          alt="CRICKET TOURNAMENT"
                          className="header-logo-top w-100 object-fit-cover"
                        />
                      </div>
                      <div className="d-flex justify-content-center w-100 my-3">
                        <div
                          className="bpl-logo overflow-hidden"
                          style={{ width: "25%" }}
                        >
                          <img
                            src={require("../../../assetes/ourassosiates/bpl-logo.png")}
                            alt=""
                            className="w-100 object-fit-cover"
                          />
                        </div>
                      </div>
                      <div className="cricket-form-header d-flex flex-column">
                        <div className="header-text d-flex justify-content-center align-items-center w-100">
                          <p
                            id="header_73"
                            className="form-header h1 cricket-header-text text-center"
                            data-component="header"
                          >
                            Bairganiya Premier League 2024 <br />
                            First Edition Tennis Ball Cricket Tournament
                          </p>
                        </div>
                        <p
                          id="subHeader_73"
                          className="form-subHeader h5  text-center mt-3"
                        >
                          Registration Form
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container p-4">
                  <div className="row align-items-center w-100 gy-3 gx-2">
                    <div className="col-md-6">
                      <label className="race-details fw-bold mb-2">
                        Team Name
                      </label>
                      <div
                        id="cid_80"
                        className="form-input-wide"
                        data-layout="half"
                      >
                        <input
                          type="text"
                          placeholder="Enter Team Name"
                          className="race-category"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="race-details fw-bold mb-2">
                        Coach Name
                      </label>
                      <div className="row g-2">
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="race-category w-100"
                            placeholder="First Name"
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="race-category w-100"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="race-details fw-bold my-3">
                        School/College Name
                      </label>
                      <CreatableSelect
                        isClearable
                        options={options}
                        onChange={(e) => HandleAddSchool(e)}
                        className="school-select"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "1px solid gray",
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          colors: {
                            ...theme.colors,
                            primary: "gray",
                          },
                        })}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="race-details fw-bold my-3">
                        Tournament Category
                      </label>
                      <select name="" id="" className="race-category">
                        <option value="Select Category" disabled selected>
                          Select Category
                        </option>
                        <option value="U_19">U-19</option>
                        <option value="open">Open</option>
                      </select>
                    </div>

                    <div className="col-md-12">
                      <label className="race-details fw-bold my-3">
                        Player Details
                      </label>
                      <div className="table-responsive">
                        <table className="player_details mb-3">
                          <tr className="form-matrix-tr form-matrix-header-tr">
                            <th className="form-matrix-th table-header">No</th>
                            <th
                              scope="col"
                              className="form-matrix-headers table-header"
                            >
                              <label id="label_81_col_0">Name</label>
                            </th>
                            <th
                              scope="col"
                              className="form-matrix-headers table-header"
                            >
                              <label id="label_81_col_1">DOB</label>
                            </th>
                            <th
                              scope="col"
                              className="form-matrix-headers table-header"
                            >
                              <label id="label_81_col_1">Age</label>
                            </th>
                            <th
                              scope="col"
                              className="form-matrix-headers table-header"
                            >
                              <label id="label_81_col_2">Position</label>
                            </th>
                            <th
                              scope="col"
                              className="form-matrix-headers table-header"
                            >
                              <label id="label_81_col_3">Arm</label>
                            </th>
                            <th
                              scope="col"
                              className="form-matrix-headers table-header"
                            >
                              <label id="label_81_col_3">ID Proof</label>
                            </th>
                            <th
                              scope="col"
                              className="form-matrix-headers table-header"
                            >
                              <label id="label_81_col_3">Health History</label>
                            </th>
                            <th
                              scope="col"
                              className="form-matrix-headers table-header"
                            >
                              <label id="label_81_col_4">T-shirt Size</label>
                            </th>
                            <th
                              scope="col"
                              className="form-matrix-headers table-header"
                            >
                              <label id="label_81_col_4">Trousers Size</label>
                            </th>
                          </tr>

                          {players.map((player, index) => (
                            <tr
                              key={index}
                              className="form-matrix-tr form-matrix-value-tr"
                            >
                              <th
                                scope="row"
                                className="form-matrix-headers table-header"
                              >
                                {index + 1}
                              </th>
                              <td className="form-matrix-values player-field name-feild-table">
                                <input
                                  type="text"
                                  placeholder="Enter Name"
                                  value={player.name}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                  className="race-category"
                                />
                              </td>
                              <td className="form-matrix-values player-field">
                                <input
                                  type="date"
                                  placeholder="Enter Date of birth"
                                  value={player.dob}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "dob",
                                      e.target.value
                                    )
                                  }
                                  className="race-category"
                                />
                              </td>
                              <td className="form-matrix-values player-field">
                                <input
                                  type="number"
                                  placeholder="Enter Age"
                                  value={player.age > 0 && player.age}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "age",
                                      e.target.value
                                    )
                                  }
                                  className="race-category"
                                  disabled
                                />
                              </td>
                              <td className="form-matrix-values player-field">
                                <input
                                  type="text"
                                  placeholder="Enter Position"
                                  value={player.position}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "position",
                                      e.target.value
                                    )
                                  }
                                  className="race-category"
                                />
                              </td>
                              <td className="form-matrix-values player-field">
                                <select
                                  value={player.arm}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "arm",
                                      e.target.value
                                    )
                                  }
                                  className="race-category"
                                >
                                  <option value="" disabled>
                                    Select Arm
                                  </option>
                                  <option value="Left">Left</option>
                                  <option value="Right">Right</option>
                                </select>
                              </td>
                              <td className="form-matrix-values player-field">
                                <input
                                  type="file"
                                  placeholder="Enter id"
                                  value={player.id_proof}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "id_proof",
                                      e.target.value
                                    )
                                  }
                                  className="race-category"
                                />
                              </td>
                              <td className="form-matrix-values player-field">
                                <select
                                  value={player.health}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "health",
                                      e.target.value
                                    )
                                  }
                                  className="race-category t-shirt"
                                >
                                  <option value="" disabled>
                                    Select Health History
                                  </option>

                                  {ArrayObj.Historus.map((item, idx) => (
                                    <option key={idx} value={item.value}>
                                      {item.value}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td className="form-matrix-values player-field">
                                <select
                                  value={player.tshirtSize}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "tshirtSize",
                                      e.target.value
                                    )
                                  }
                                  className="race-category t-shirt"
                                >
                                  <option value="" disabled selected>
                                    Select T-shirt Size
                                  </option>

                                  {ArrayObj.tshirtSize.map((item, idx) => (
                                    <option key={idx} value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td className="form-matrix-values player-field">
                                <select
                                  value={player.pentSize}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "pentSize",
                                      e.target.value
                                    )
                                  }
                                  className="race-category"
                                >
                                  <option value="" disabled>
                                    Select Trouser Size
                                  </option>
                                  {ArrayObj.trouserSize.map((item, idx) => (
                                    <option key={idx} value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="race-details fw-bold mb-2">
                        Mailing Address
                      </label>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter email"
                          className="race-category"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="race-details fw-bold mb-2">
                        Phone Number
                      </label>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter phone Number"
                          className="race-category"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Address
                      </label>
                      <textarea
                        name=""
                        id=""
                        rows={4}
                        className="race-details fw-semibold mb-2 w-100 text-area-add"
                      ></textarea>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        City
                      </label>
                      <input
                        type="text"
                        placeholder="Enter City"
                        className="race-category"
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        State
                      </label>
                      <input
                        type="text"
                        placeholder="Enter state"
                        className="race-category"
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="" className="race-details fw-bold mb-2">
                        Pin code*
                      </label>
                      <input
                        id="pin_code"
                        type="tel"
                        className="race-category"
                        placeholder="Enter Pin code"
                        inputMode="numeric"
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="race-details fw-bold my-3">
                        Emergency Contact
                      </label>
                      <input
                        type="tel"
                        placeholder="Enter Emergency Contact Number"
                        className="race-category"
                        inputMode="numeric"
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="race-details fw-bold my-3">
                        Doctor's Contact
                      </label>
                      <input
                        type="tel"
                        placeholder="Enter Doctor Contact Number"
                        className="race-category"
                        inputMode="numeric"
                      />
                    </div>
                    <div className="col-md-12">
                      <div
                        className="jfQuestion-fields"
                        data-wrapper-react="true"
                      >
                        <div
                          className="jfField isFilled"
                          onClick={handleLabelClick}
                        >
                          <div className="jfUpload-wrapper">
                            <div className="jfUpload-files-container">
                              <label
                                className="form-label upload-demo form-label-top form-label-auto w-100"
                                style={{ cursor: "pointer" }}
                              >
                                Upload Team Photo
                              </label>
                              <input
                                type="file"
                                className="form-upload-multiple"
                                style={{ display: "none" }}
                                ref={fileInputRef}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="submit-cricket-form"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="submit-cricket-form submit-form"
                onClick={handleSave}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CricketTeamForm;
