import React, { useEffect } from "react";

import CreatableSelect from "react-select/creatable";
import ArrayObj from "../../../Constant/arrayObj";
import Pako from "pako";
import { ImCross } from "react-icons/im";
import { useState } from "react";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./bairganiyamahostav.css";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Loader";

const ParticipentForm = ({ onClose, onDance }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const RAZORPAY_API_KEY = process.env.REACT_APP_RAZORPAY_KEY_ID;
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  // participent Details
  const [event, setEvent] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [idProof, setIdProof] = useState("");
  const [mState, setMState] = useState("");
  const [mCountry, setMCountry] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [add, setAdd] = useState("");
  const [pincode, setPincode] = useState("");
  const [emergencyNumber, setEmergencyNumber] = useState("");
  const [relationNumber, setRelationNumber] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [doctorContact, setDoctoerContact] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [schoolName, setSchoolName] = useState("");

  const [countryCode, setCountryCode] = useState("+91");
  const [base64, setBase64] = useState("");
  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [validCheck, setValidCheck] = useState(false);
  const [termsandconditionCheck, setTermsandconditionCheck] = useState(false);
  const [other, setOther] = useState(false);
  const [getPrice, setGetPrice] = useState(0);
  const [loader, setLoader] = useState(false);
  const [participent, setParticipent] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [valid, setValid] = useState("");
  const navigate = useNavigate();
  let token = JSON.parse(localStorage.getItem("token"));
  const [danceEvent, setDanceEvent] = useState(null);
  const [typeOfDance, setTypeOfDance] = useState("");

  useEffect(() => {
    setValue("lastname", lName);
    setValue("pincod", pincode);
    setValue("cityOne", city);
    setValue("address", add);
    setValue("school", schoolName);
  }, [lName, pincode, city, add, schoolName, setValue]);

  useEffect(() => {
    if (lName) {
      clearErrors("lastname");
    }
    if (pincode) {
      clearErrors("pincod");
    }
    if (city) {
      clearErrors("cityOne");
    }
    if (add) clearErrors("address");
    if (schoolName) clearErrors("schoolName");
  }, [lName, pincode, city, add, schoolName, clearErrors]);

  useEffect(() => {
    GetCountries().then((result) => {
      let filteredCountries = result.filter(
        (item) => item.iso2 === "IN" || item.iso2 === "NP"
      );

      setSelectedCountry(filteredCountries);
    });
    setDanceEvent(onDance);
  }, [schoolName, other, collegeName, event, getPrice]);

  const onSelectCountry = (data) => {
    setCountryid(data.target.value);
    GetState(Number(data.target.value)).then((result) => {
      setStateList(result);
    });

    setMState(stateList);
    setMCountry(data.target.value);
  };

  const onSelectState = (data) => {
    setStateid(data.target.value);
    GetCity(Number(data.target.value)).then((result) => {
      setCityList(result);
    });
    setMState(data.target.value);
  };

  const options = ArrayObj.schoolList.map((item) => ({
    value: item.name.toLowerCase(),
    label: item.name,
    other: item.is_other,
  }));

  const HandleAddSchool = (name) => {
    if (name) {
      setSchoolName(name.value);
      setOther(name?.other);
    }
  };

  const onAddPrograms = (event) => {
    let data = JSON.parse(event.target.value);
    setEvent(data.label);
    setGetPrice(data.price);
  };

  // covert img in base64

  const uint8ArrayToBase64 = (uint8Array) => {
    const binaryString = Array.from(uint8Array)
      .map((byte) => String.fromCharCode(byte))
      .join("");
    return window.btoa(binaryString);
  };

  const onConvertImg = async (data) => {
    let compressedBase64String = "";

    const event = data;

    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        compressedBase64String = compressBase64(base64String);
        setBase64(compressedBase64String);
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      reader.readAsDataURL(file);
    }
  };

  const base64ToUint8Array = (base64) => {
    const binaryString = window.atob(base64.split(",")[1]);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
  };

  const compressBase64 = (base64String) => {
    const uint8Array = base64ToUint8Array(base64String);

    const compressed = Pako.deflate(uint8Array);

    return uint8ArrayToBase64(compressed);
  };

  const closeModal = () => {
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) {
      backdrop.classList.remove("show");

      backdrop.remove();
    }
    onClose();
  };

  // add participent
  const submitParticipentData = () => {
    const payload = {
      program_category: "Chairity",
      program: event,
      first_name: fName,
      last_name: lName,
      gender: gender,
      dob: dob,
      email: email,
      phonenumber: `${countryCode}${phone}`,
      school_name: other ? collegeName : schoolName,
      identity_proof_url: idProof,
      identity_type: base64,
      city: city,
      state: mState,
      country: mCountry,
      area: area,
      address: add,
      pin_code: pincode,
      emergency_contact: `${countryCode}${emergencyNumber}`,
      contact_relation: relationNumber,
      dr_contact: doctorContact,
      dr_name: doctorName,
    };
    setLoader(true);

    axios
      .post(`${BASE_URL}/api/user/mhotsav/register`, payload, {
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        let data = response.data.data;

        setParticipent(data);
        getParticipentData();
        setLoader(false);
        toast.success("Registration successful!");
        participentPayment(data);
        closeModal();
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
      })
      .finally(function () {});

    setEvent("");
    setFName("");
    setLName("");
    setGender("");
    setEmail("");
    setPhone("");
    setAdd("");
    setArea("");
    setBase64("");
    setMCountry("");
    setMState("");
    setCollegeName("");
    setSchoolName("");
    setDob("");
    setDoctoerContact("");
    setDoctorName("");
    setEmergencyNumber("");
    setRelationNumber("");
    setIdProof("");
    setGetPrice(0);
    setPincode("");
    setTermsandconditionCheck(false);
    setValidCheck(false);
    reset();
  };

  // get participent
  const getParticipentData = () => {
    axios
      .get(`${BASE_URL}/api/user/mhotsav/data`, {
        headers: {
          authorization: token,
        },
      })

      .then((response) => {
        console.log(response);
        setParticipent(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {});
  };

  // Order create of payment
  const participentPayment = async (participent) => {
    setLoader(true);

    try {
      const orderUrl = `${BASE_URL}/api/user/mhotsav/order/create`;

      const { data } = await axios.post(
        orderUrl,
        {
          amount: 1,
          // "race_id": "abcd",
          mhotsav_id: participent._id,
        },
        {
          headers: {
            authorization: token,
          },
        }
      );

      setLoader(false);

      const options = {
        key: RAZORPAY_API_KEY,
        amount: 1,
        currency: data.currency,
        name: "RAUNIYAR FOUNDATIN BHARAT",
        description: "MARTHON RAGISTRATION CHARITY AMOUNT",
        order_id: data?.data,
        handler: function (response) {
          verifyPayment(response, participent);
          setLoader(true);
        },
        prefill: {
          name: `${participent?.first_name} ${participent?.last_name}`,
          email: participent?.email,
          contact: participent?.phonenumber,
          participant_id: participent?._id,
        },
        theme: {
          color: "#e41c3c",
        },
      };

      const paymentObject = new window.Razorpay(options, participent?._id);
      console.log("paymentObject==>", paymentObject);

      const response = paymentObject.open();
    } catch (error) {
      console.error("Error while creating order:", error);
    }
  };

  const verifyPayment = (data, participant) => {
    const payload = {
      order_id: data.razorpay_order_id,
      payment_id: data.razorpay_payment_id,
      signature: data.razorpay_signature,
      mhotsav_id: participant?._id,
      amount: getPrice,
    };
    console.log("participant_verify", payload);
    axios
      .post(`${BASE_URL}/api/user/mhotsav/order/payment/verify`, payload, {
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log("response", response);

        toast.success("Payment successful!");
        setLoader(false);
        navigate("/bairganiaMahotsav");
        setShowPopup(true);
        onClose();
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Oops Payment Not Varify!");
        setLoader(false);
        navigate("/bairganiaMahotsav");
        toast.error("Please Try Again!");
      })
      .finally(function () {
        setLoader(false);
      });
  };

  return (
    <>
      <div className="modal-dialog modal-dialog-right responsive_modal">
        <div className="modal-content register-content">
          <div className="modal-header register-modal-header d-flex  align-items-center">
            <p className="modal-body-title text-light mb-0">Add Participant</p>

            <button
              type="button"
              className="btn rounded-circle close-btn text-light"
              data-bs-dismiss="modal"
            >
              <ImCross />
            </button>
          </div>
          <div className="modal-body participnet-modal-body">
            <p className="modal-body-dec mb-5">
              Enter each participant's details. If you are an existing user,
              your information will be pre-filled.
            </p>

            <div className="mt-3">
              <div className="merathone-details fw-semibold mb-2">
                Program Details
              </div>
              <div className="row w-100 gx-3 gy-4 mb-4">
                <div className="col-md-6">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    Program Category
                  </label>
                  <select name="" id="" className="race-category">
                    <option value="Chairity" selected>
                      Chairity
                    </option>
                  </select>
                </div>

                <div className="col-md-6">
                  <label htmlFor="event" className="race-details fw-bold mb-2">
                    Program's
                  </label>
                  <select
                    {...register("program", {
                      required: "Program event is required",
                    })}
                    className="race-category"
                    onChange={(e) => onAddPrograms(e)}
                  >
                    <option value="Select Program" selected disabled>
                      Select Program
                    </option>
                    {ArrayObj.Programs.map((item, index) => (
                      <option key={index} value={JSON.stringify(item)}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  {errors.program && (
                    <p className="invalid">{errors.program.message}</p>
                  )}
                </div>

                <div className="merathone-details fw-semibold mb-2">
                  Personal Details
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    First Name*
                  </label>
                  <input
                    {...register("first_name", {
                      required: true,
                      pattern: /^[A-Za-z]+$/i,
                    })}
                    type="text"
                    className="race-category"
                    placeholder="Enter Name"
                    value={fName}
                    onChange={(e) => setFName(e.target.value)}
                  />
                  {errors?.first_name?.type === "required" && (
                    <p className="invalid">This field is required</p>
                  )}
                </div>

                <div className="col-md-6">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    Last Name*
                  </label>
                  <input
                    {...register("lastname", {
                      required: true,
                      pattern: /^[A-Za-z]+$/i,
                    })}
                    type="text"
                    className="race-category"
                    placeholder="Enter last name"
                    value={lName}
                    onChange={(e) => setLName(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    Gender*
                  </label>
                  <select
                    className="race-category"
                    {...register("gender", { required: "Select your Gender" })}
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="" selected disabled>
                      Select Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  {errors.gender && (
                    <p className="invalid">{errors.gender.message}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="dob" className="race-details fw-bold mb-2">
                    Date of Birth*
                  </label>
                  <input
                    {...register("dob", {
                      required: "Date of Birth is required",
                    })}
                    type="date"
                    pattern="mm/dd/yyyy"
                    className="race-category"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                  />
                  {errors.dob && (
                    <p className="invalid">{errors.dob.message}</p>
                  )}
                </div>
                <div className="col-md-12">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    Email Address*
                  </label>
                  <input
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Enter a valid email address",
                      },
                    })}
                    type="email"
                    className="race-category"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <p className="invalid">{errors.email.message}</p>
                  )}
                </div>
                <div className="col-md-12">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    School / College Name*
                  </label>

                  <CreatableSelect
                    {...register("school", {
                      required: "school name is required",
                    })}
                    isClearable
                    options={options}
                    onChange={HandleAddSchool}
                    className="school-select"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "1px solid gray",
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      colors: {
                        ...theme.colors,
                        primary: "gray",
                      },
                    })}
                  />
                  {errors.school && (
                    <p className="invalid">{errors.school.message}</p>
                  )}
                </div>

                {other && (
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="race-category"
                      placeholder="Enter school name"
                      value={collegeName}
                      onChange={(e) => setCollegeName(e.target.value)}
                      required
                    />
                  </div>
                )}

                <div className="col-md-12">
                  <label htmlFor="phone" className="race-details fw-bold mb-2">
                    Mobile no.*
                  </label>
                  <div className="race-mobile rounded-3 d-flex align-items-center gap-2">
                    <select
                      className="country-drop"
                      onChange={(e) => setCountryCode(e.target.value)}
                    >
                      <option value="+91">+91 (INA)</option>
                      <option value="+977">+977 (NP)</option>
                    </select>
                    <input
                      {...register("phonenumber", {
                        required: "Mobile number is required",
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Numeric characters only",
                        },
                      })}
                      type="tel"
                      className="race-category"
                      placeholder="Enter Mobile Number"
                      maxLength={10}
                      inputMode="numeric"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  {errors.phonenumber && (
                    <p className="invalid">{errors.phonenumber.message}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    Identity Proof Type*
                  </label>
                  <select
                    className="race-category"
                    {...register("identity_type", {
                      required: "Identity Proof Type is required",
                    })}
                    value={idProof}
                    onChange={(e) => setIdProof(e.target.value)}
                  >
                    <option value="" selected disabled>
                      Select Identity Proof Type*
                    </option>
                    {ArrayObj.personalDetails?.map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  {errors.identity_type && (
                    <p className="invalid">{errors.identity_type.message}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    Select {idProof == "" ? "PersonalDetails" : idProof}
                  </label>
                  <input
                    type="file"
                    className="race-category"
                    {...register("identity_proof_url", {
                      required: "ID Card is required",
                    })}
                    onChange={(e) => onConvertImg(e)}
                  />
                  {errors.identity_proof_url && (
                    <p className="invalid">
                      {errors.identity_proof_url.message}
                    </p>
                  )}
                </div>
                {danceEvent && (
                  <>
                    <div className="merathone-details fw-semibold mb-2">
                      Type of Dance
                    </div>
                    <div className="row w-100">
                      <div className="col-md-6">
                        <input
                          type="radio"
                          name="dance"
                          id=""
                          className="me-2"
                          value="Salsa"
                          onChange={(e) => setTypeOfDance(e.target.value)}
                        />
                        <label htmlFor="">Salsa</label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="radio"
                          name="dance"
                          id=""
                          className="me-2"
                          value="Bachata"
                          onChange={(e) => setTypeOfDance(e.target.value)}
                        />
                        <label htmlFor="">Bachata</label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="radio"
                          name="dance"
                          id=""
                          className="me-2"
                          value="Tango"
                          onChange={(e) => setTypeOfDance(e.target.value)}
                        />
                        <label htmlFor="">Tango</label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="radio"
                          name="dance"
                          id=""
                          className="me-2"
                          value="Club"
                          onChange={(e) => setTypeOfDance(e.target.value)}
                        />
                        <label htmlFor="">Club</label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="radio"
                          name="dance"
                          id=""
                          className="me-2"
                          value="Hip Hop"
                          onChange={(e) => setTypeOfDance(e.target.value)}
                        />
                        <label htmlFor="">Hip Hop</label>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="radio"
                          name="dance"
                          id=""
                          className="me-2"
                          value="other"
                          onChange={(e) => setTypeOfDance(e.target.value)}
                        />
                        <label htmlFor="">Other</label>
                      </div>
                    </div>
                    {typeOfDance == "other" && (
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="race-category"
                          placeholder="Enter your Dance type"
                        />
                      </div>
                    )}
                  </>
                )}

                <div className="merathone-details fw-semibold mb-2">
                  Residential Details
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    Country*
                  </label>
                  <select
                    {...register("country", {
                      required: "Country is required",
                    })}
                    className="race-category"
                    onChange={(e) => onSelectCountry(e)}
                  >
                    <option value="Select Country" disabled selected>
                      Select Country
                    </option>
                    {selectedCountry.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.country && (
                    <p className="invalid">{errors.country.message}</p>
                  )}
                </div>

                <div className="col-md-6">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    State*
                  </label>
                  <select
                    className="race-category"
                    {...register("state", {
                      required: "state is required",
                    })}
                    onChange={(e) => onSelectState(e)}
                  >
                    <option value="Select State" selected disabled>
                      Select State
                    </option>
                    {stateList.map((item, index) => (
                      <option
                        value={item?.id}
                        key={index}
                        className="text-dark"
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errors.state && (
                    <p className="invalid">{errors.state.message}</p>
                  )}
                </div>

                <div className="col-md-6">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    City*
                  </label>
                  <input
                    {...register("cityOne", {
                      required: "City is required",
                    })}
                    type="text"
                    placeholder="Enter City"
                    className="race-category"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                  {errors.cityOne && (
                    <p className="invalid">{errors.cityOne.message}</p>
                  )}
                </div>

                <div className="col-md-6">
                  <label htmlFor="area" className="race-details fw-bold mb-2">
                    Area*
                  </label>
                  <input
                    {...register("area", {
                      required: "Area is required",
                    })}
                    id="area"
                    type="text"
                    className="race-category"
                    placeholder="Enter Area"
                    value={area}
                    onChange={(e) => setArea(e.target.value)}
                  />
                  {errors.area && (
                    <p className="invalid">{errors.area.message}</p>
                  )}
                </div>

                <div className="col-md-8">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    Address*
                  </label>
                  <textarea
                    {...register("address", {
                      required: "Address is required",
                    })}
                    rows={3}
                    className="race-details fw-semibold mb-2 w-100 text-area-add"
                    value={add}
                    onChange={(e) => setAdd(e.target.value)}
                  ></textarea>
                  {errors.address && (
                    <p className="invalid">{errors.address.message}</p>
                  )}
                </div>

                <div className="col-md-4">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    Pin code*
                  </label>
                  <input
                    {...register("pincod", {
                      required: "Pin code is required",
                    })}
                    id="pin_code"
                    type="tel"
                    className="race-category"
                    placeholder="Enter Pin code"
                    inputMode="numeric"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                  />
                  {errors.pincod && (
                    <p className="invalid">{errors.pincod.message}</p>
                  )}
                </div>
                <div className="merathone-details fw-semibold mb-2">
                  Emergency Contact Details
                </div>

                <div className="col-md-7">
                  <label htmlFor="phone" className="race-details fw-bold mb-2">
                    Mobile no.*
                  </label>
                  <div className="race-mobile rounded-3 d-flex align-items-center w-100 gap-2">
                    <select
                      className="country-drop"
                      onChange={(e) => setCountryCode(e.target.value)}
                    >
                      <option value="+91">+91 (INA)</option>
                      <option value="+977">+977 (NP)</option>
                    </select>
                    <input
                      {...register("emergency_contact", {
                        required: "Mobile number is required",
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Numeric characters only",
                        },
                      })}
                      type="tel"
                      className="race-category"
                      placeholder="Enter Mobile Number"
                      maxLength={10}
                      inputMode="numeric"
                      value={emergencyNumber}
                      onChange={(e) => setEmergencyNumber(e.target.value)}
                    />
                  </div>
                  {errors.emergency_contact && (
                    <p className="invalid">
                      {errors.emergency_contact.message}
                    </p>
                  )}
                </div>

                <div className="col-md-5">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    Relation*
                  </label>
                  <select
                    className="race-category"
                    {...register("contact_relation", {
                      required: "Select your relation",
                    })}
                    value={relationNumber}
                    onChange={(e) => setRelationNumber(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Select Relation
                    </option>
                    {ArrayObj.relation.map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  {errors.contact_relation && (
                    <p className="invalid">{errors.contact_relation.message}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    Family Doctoer's Name*
                  </label>
                  <input
                    {...register("dr_name", {
                      required: "Doctor's Name is required",
                    })}
                    className="race-category"
                    placeholder="Enter Doctor's name"
                    value={doctorName}
                    onChange={(e) => setDoctorName(e.target.value)}
                  />
                  {errors.dr_name && (
                    <p className="invalid">{errors.dr_name.message}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="race-details fw-bold mb-2">
                    Family Doctor's Number*
                  </label>
                  <div className="race-mobile rounded-3 d-flex align-items-center w-100 gap-2">
                    <select
                      className="country-drop"
                      onChange={(e) => setCountryCode(e.target.value)}
                      style={{ width: "36%" }}
                    >
                      <option value="+91">+91 (INA)</option>
                      <option value="+977">+977 (NP)</option>
                    </select>
                    <input
                      {...register("dr_contact", {
                        required: "Doctor's Contact is required",
                      })}
                      type="tel"
                      className="race-category"
                      placeholder="Enter Doctor's Number"
                      maxLength={10}
                      value={doctorContact}
                      onChange={(e) => setDoctoerContact(e.target.value)}
                      inputMode="numeric"
                    />
                  </div>
                  {errors.dr_contact && (
                    <p className="invalid">{errors.dr_contact.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center w-100 gap-2 ">
              <input
                type="checkbox"
                name=""
                id=""
                className="form-check-input"
                value={validCheck}
                onChange={(e) => setValidCheck(e.target.checked)}
                required
              />
              <span className="check-text">
                Consent to participate in the marathon
              </span>
            </div>
            <div className="d-flex align-items-center w-100 gap-2 mt-2 mb-4">
              <input
                type="checkbox"
                name=""
                id=""
                className="form-check-input"
                value={termsandconditionCheck}
                onChange={(e) => setTermsandconditionCheck(e.target.checked)}
                required
              />
              <span className="check-text">
                Agree to our &nbsp;
                <Link
                  className="check-link"
                  to={"/mahostavtermsandcondition"}
                  target="_blank"
                >
                  Terms and Conditions{" "}
                </Link>{" "}
                and &nbsp;
                <Link
                  className="check-link"
                  to={"/mahostavprivacypolicy"}
                  target="_blank"
                >
                  Privacy Policy
                </Link>
              </span>
            </div>
            <div className="mahostav-btn d-flex align-items-center justify-content-center gap-2 w-100 mb-4">
              <div className="cancel-btn d-flex  align-items-center w-100">
                <button
                  className="cancle-paricipent-btn fw-semibold w-100"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
              <div className="submit-btn d-flex align-items-center w-100">
                <button
                  className={`submit-participent-btn fw-semibold text-light w-100 ${
                    (!validCheck && !termsandconditionCheck) ||
                    (validCheck && !termsandconditionCheck) ||
                    (!validCheck && termsandconditionCheck)
                      ? "disable"
                      : "submit-mahostav"
                  }`}
                  onClick={handleSubmit(submitParticipentData)}
                  disabled={
                    (!validCheck && !termsandconditionCheck) ||
                    (validCheck && !termsandconditionCheck) ||
                    (!validCheck && termsandconditionCheck)
                  }
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader />}
      {showPopup ? (
        <div className="popup">
          <div className="popup_inner text-center">
            <div className="header">
              <p className="h3 fw-semibold">Thanks You 😊</p>
            </div>
            <hr />
            <p>Pleases Check your payment details on your register email..!</p>
            <button
              className="btn btn-primary m-auto my-3"
              onClick={() => setShowPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ParticipentForm;
