import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import CricketTeamForm from "./CricketTeamForm";

const BairganiyaCricketLeage = () => {
  const bplRules = [
    "बीपीएल गेम्स में टेलीविजन टाइमआउट शामिल है। प्रत्येक टीम को ढाई मिनट का 'रणनीतिक टाइम-आउट' दिया जाता है।",
    "प्रत्येक पारी के दौरान एक गेंदबाजी टीम को सातवें और नौवें ओवर के बीच लेना होगा और दूसरा 14वें और 16वें ओवर के बीच बल्लेबाजी टीम को लेना होगा।",
    "यदि अंपायर टीमों को इस विशेषाधिकार का दुरुपयोग करते हुए पाते हैं तो जुर्माना लगाया जा सकता है।",
    "यदि गेंदबाजी टीम आवंटित समय में अपने ओवर पूरे नहीं करती है, तो वह शेष पारी के लिए क्षेत्ररक्षण प्रतिबंध सर्कल के बाहर केवल चार क्षेत्ररक्षकों को रख सकती है, या मैच रेफरी मैच के बाद गेंदबाजी टीम पर प्रतिबंध लगा सकता है, जिसमें खिलाड़ियों पर उनकी मैच खेलने के अनुपात में प्रतिबंध लगाया जा सकता है।",
    "टीमें संभावित विकल्प के रूप में नामित पांच खिलाड़ियों की सूची में से एक विकल्प का उपयोग कर सकती हैं, जिसे 'प्रभावशाली खिलाड़ी' कहा जाता है।",
    "प्रतिस्थापन पारी की शुरुआत से पहले, विकेट गिरने पर, बल्लेबाज के रिटायर होने पर या ओवर के अंत में किया जा सकता है।",
    "दोनों टीमें प्रति मैच एक बार एक विकल्प पेश कर सकती हैं।",
    "टीमें टॉस से पहले या बाद में मैच रेफरी को अपनी अंतिम एकादश (Playing 11) घोषित कर सकती हैं।",
    "यदि गेंदबाज के गेंदबाजी करते समय कोई क्षेत्ररक्षक या विकेटकीपर अनुचित हरकत करता है तो पांच रन का जुर्माना लगाया जाता है और उस गेंद को डेड बॉल दिया जाएगा।",
    "प्रत्येक टीम के लिए अपनी अंतिम एकादश (Playing 11) में कम से कम चार महिला खिलाड़ियों को शामिल करना अनिवार्य होगा।",
    "प्रत्येक टीम को अपनी टीम में 15 खिलाड़ियों को शामिल करना होगा, जिसमें कम से कम पाँच महिला खिलाड़ियों को शामिल करना अनिवार्य है।",
  ];
  const bplPrice = [
    {
      header: "ऑरेंज कैप",
      txt: [
        "सीज़न के अंत में सबसे अधिक रन बनाने वाले खिलाड़ी को प्रदान की जाएगी। यह एक सतत प्रतियोगिता है जिसमें वर्तमान में सर्वाधिक रन बनाने वाला खिलाड़ी क्षेत्ररक्षण करते समय टोपी पहनता है। अंतिम विजेता सीज़न के लिए कैप रखता है।",
      ],
    },
    {
      header: "पर्पल कैप",
      txt: [
        "पर्पल कैप सीज़न के अंत में सबसे अधिक विकेट लेने वाले गेंदबाज को प्रदान की जाती है। यह एक सतत प्रतियोगिता है और जो गेंदबाज अग्रणी विकेट लेने वाला गेंदबाज है वह क्षेत्ररक्षण करते समय बैंगनी टोपी पहनता है। सीज़न के अंत में अग्रणी विकेट लेने वाला पुरस्कार जीतता है।",
      ],
    },
    {
      header: "सबसे मूल्यवान खिलाड़ी",
      txt: [
        "सबसे मूल्यवान खिलाड़ी का पुरस्कार, जिसे 'मैन ऑफ़ द टूर्नामेंट' कहा जाता है।",
      ],
    },
    {
      header: "फेयर प्ले अवार्ड",
      txt: [
        "फेयर प्ले अवार्ड प्रत्येक सीज़न के बाद सर्वश्रेष्ठ फेयर प्ले रिकॉर्ड वाली टीम को दिया जाता है। प्रत्येक मैच के बाद, दो ऑन-फील्ड अंपायर और तीसरा अंपायर दोनों टीमों के प्रदर्शन का स्कोर बनाते हैं, जिसमें सीज़न के अंत में उच्चतम स्कोरिंग टीम होती है।",
      ],
    },
    {
      header: "उभरते खिलाड़ी पुरस्कार",
      txt: [
        "इमर्जिंग प्लेयर अवार्ड 2024 में सर्वश्रेष्ठ खिलाड़ी को प्रदान किया जाएगा। इस पुरस्कार को वर्ष का उभरता हुआ खिलाड़ी कहा जाता है।",
      ],
    },
    {
      header: "अधिकतम छक्के का पुरस्कार",
      txt: [
        "सीज़न के अंत में सबसे अधिक छक्के लगाने वाले खिलाड़ी को अधिकतम छक्के का पुरस्कार प्रदान किया जाएगा है।",
      ],
    },
    {
      header:
        "बीपीएल 2024 से पहले आपको बैरगनिया प्रीमियर लीग के नियमों के बारे में जानने की जरूरत है",
      txt: [
        "बैरगनिया प्रीमियर लीग (बीपीएल) का 2024 संस्करण नवम्बर मैं शुरू होने के लिए पूरी तरह तैयार है, जिसमें 8 ग्राम पंचायत,26 वार्ड ,69 सरकारी स्कूल ,25 प्राइवेट स्कूल तथा कॉलेज -आधारित टीमों से भिड़ेंगे। बीपीएल 2024 का पहला सीज़न होगा, जिसमें प्रत्येक टीम लीग चरण में क्रमशः 60 गेम खेलेगी। 22 दिनों में 3 स्थानों पर कुल 60 लीग-चरण मैच आयोजित किए जाएंगे। प्लेऑफ़ के कार्यक्रम की घोषणा बाद में की जाएगी। बैरगनिया प्रीमियर लीग (बीपीएल) 2024 का फाइनल 25 दिसंबर को खेला जाएगा।.",

        "बैरगनिया प्रीमियर लीग (बीपीएल) 2024 के दौरान कप्तान दो अलग-अलग टीम शीट के साथ टॉस के लिए जा सकते हैं। टॉस के बाद कप्तान अपनी अंतिम एकादश का खुलासा कर सकते हैं। टीमों को खेल की परिस्थितियों और चाहे वे पहले बल्लेबाजी कर रहे हों या गेंदबाजी कर रहे हों, के आधार पर अपनी अंतिम एकादश चुनने का सबसे अच्छा मौका देने के लिए यह नियम रखा गया है।कप्तानों को टॉस से पहले टीमों की अदला-बदली करनी होती है। इसे टॉस के तुरंत बाद टीमों के आदान-प्रदान में बदल दिया गया है ताकि टीमें सर्वश्रेष्ठ एकादश का चयन कर सकें, यह इस बात पर निर्भर करता है कि वे पहले बल्लेबाजी कर रहे हैं या गेंदबाजी कर रहे हैं। इससे टीमों को प्रभावशाली खिलाड़ी की योजना बनाने में भी मदद मिलेगी।",
      ],
    },
    {
      header: "प्रभाव खिलाड़ी नियम",
      txt: [
        "एक इम्पैक्ट प्लेयर प्रति टीम एक स्थानापन्न खिलाड़ी को बीपीएल मैच में अधिक सक्रिय भाग लेने की अनुमति देता है। यह खेल में एक नया सामरिक/रणनीतिक आयाम जोड़ेगा। टॉस के समय टीमों को प्लेइंग इलेवन और 5 (पांच) विकल्प की पहचान करनी होगी। टीम शीट में नामित 5 विकल्पों में से केवल एक खिलाड़ी को इम्पैक्ट खिलाड़ी के रूप में उपयोग किया जा सकता है। ",

        "कप्तान इम्पैक्ट प्लेयर को अंपायर के पास नामांकित करेगा। इम्पैक्ट प्लेयर को पारी शुरू होने से पहले, ओवर पूरा होने के बाद, या बल्लेबाज के मामले में, विकेट गिरने पर या ओवर के दौरान किसी भी समय बल्लेबाज के रिटायर होने पर पेश किया जा सकता है। ",
        "गेंदबाजी करने वाली टीम को विकेट गिरने के दौरान एक इम्पैक्ट प्लेयर भी मिल सकता है, लेकिन अगर विकेट बीच में गिरा है तो उस इम्पैक्ट प्लेयर को उस ओवर में बाकी गेंदें फेंकने की अनुमति नहीं होगी। जब गेंदबाजी टीम अपने इम्पैक्ट प्लेयर को लाती है, तो उन्हें अपने चार ओवरों का पूरा कोटा फेंकने की अनुमति होगी, चाहे वे जिस खिलाड़ी की जगह ले रहे हों, उसने कितने भी ओवर फेंके हों।",
        "केवल एक महिला खिलाड़ी जो टीम शीट में नामित विकल्प का हिस्सा है, उसे इम्पैक्ट प्लेयर के रूप में इस्तेमाल किया जा सकता है। यदि कोई टीम किसी मैच में किसी महिला खिलाड़ी को इम्पैक्ट प्लेयर के रूप में पेश करती है, तो किसी भी परिस्थिति में 5वां महिला खिलाड़ी मैदान पर नहीं उतर सकता।",
        "एक खिलाड़ी जिसे इम्पैक्ट प्लेयर ('रिप्लेस्ड प्लेयर') द्वारा प्रतिस्थापित किया जाता है, वह अब शेष मैच में भाग नहीं ले सकता है और उसे स्थानापन्न क्षेत्ररक्षक के रूप में लौटने की भी अनुमति नहीं है।",
      ],
    },
    {
      header: "डीआरएस नियम",
      txt: [
        "बीपीएल 2024 में प्रत्येक पारी के लिए कोई भी डीआरएस नहीं होंगे।\n'आउट' और 'नॉट आउट' के अंपायरिंग निर्णयों को कोइ भी टीम चुनौती नहीं दे सकता है।",
      ],
    },
    {
      header: "ओवर-रेट पर जुर्माना",
      txt: [
        "आवंटित समय में प्रत्येक ओवर पूरा न करने पर 30-यार्ड सर्कल के बाहर केवल चार क्षेत्ररक्षकों पर ओवर-रेट का जुर्माना लगाया जाएगा।",
      ],
    },
    {
      header: "विकेटकीपर की अनुचित हरकत",
      txt: [
        "विकेटकीपर द्वारा अनुचित हरकत की स्थिति में, कोई भी अंपायर डेड बॉल का संकेत देगा और दूसरे अंपायर को ऐसा करने का कारण बताएगा। गेंदबाज का अंतिम अंपायर वाइड या नो बॉल के लिए एक रन का जुर्माना दे सकता है, यदि लागू हो, या बल्लेबाजी पक्ष को 5 पेनल्टी रन दे सकता है। किसी क्षेत्ररक्षक द्वारा अनुचित हरकत के परिणामस्वरूप डेड बॉल और पांच पेनल्टी रन भी हो सकते हैं।",
      ],
    },
    {
      header: "महिला खिलाड़ी की सीमा",
      txt: [
        "प्रत्येक टीम किसी भी मैच के लिए शुरुआती एकादश में कम से कम चार महिला खिलाड़ी रखना अनिवार्य है। किसी भी मैच के दौरान किसी भी समय एक टीम के पास खेल के मैदान पर चार से अधिक महिला खिलाड़ी रख सकते है। इस प्रकार, यदि टीम अपने शुरुआती एकादश में अधिकतम चार महिला खिलाड़ियों को नामित करती है, तो एक महिला खिलाड़ी केवल स्थानापन्न क्षेत्ररक्षक के रूप में मैदान में उतर सकता है यदि वह जिस खिलाड़ी की जगह लेता है वह पुरुष क्यों न हो।",
      ],
    },
    {
      header: "कन्कशन प्रतिस्थापन",
      txt: [
        "यदि किसी खिलाड़ी को संबंधित मैच के दौरान सिर या गर्दन की चोट के कारण चोट लगने या संदिग्ध चोट लगने का संदेह होता है, तो कनकशन प्रतिस्थापन की अनुमति दी जा सकती है। घटना के बाद जितनी जल्दी हो सके कन्कशन रिप्लेसमेंट अनुरोध बीपीएल मैच रेफरी को प्रस्तुत किया जाना चाहिए। यदि प्रतिस्थापन समान-जैसा खिलाड़ी है तो बीपीएल मैच रेफरी कन्कशन प्रतिस्थापन अनुरोध को मंजूरी दे सकता है।",
      ],
    },
    {
      header: "समय बहिष्कार",
      txt: [
        "प्रत्येक पारी में 2 मिनट और 30 सेकंड की अवधि के दो टाइम-आउट होते हैं। टाइम-आउट टीमों को सामरिक रूप से फिर से समूह बनाने की अनुमति देने के लिए है। क्षेत्ररक्षक का टाइम-आउट ओवर 6 और 9 के अंत के बीच लिया जा सकता है, और बल्लेबाज का टाइम-आउट ओवर 13 और 16 के अंत के बीच लिया जा सकता है।",
      ],
    },
    {
      header: "सुपर ओवर",
      txt: [
        "यदि दोनों पारियों के पूरा होने के बाद टीमों का स्कोर बराबर है, तो एक सुपर ओवर खेला जाएगा। यदि सुपर ओवर टाई होता है, तो जब तक असाधारण परिस्थितियाँ उत्पन्न न हों, बाद के सुपर ओवर टाई मैच के वास्तविक समापन समय से एक घंटे तक खेले जाएंगे जब तक कि निर्बाध मैच में विजेता न हो जाए। मैच रेफरी टीमों को सूचित करेगा कि आखिरी सुपर ओवर कब शुरू होगा। यदि विजेता का निर्धारण करने के लिए आवश्यक सुपर ओवर खेलना या पूरा करना असंभव है तो मैच टाई हो जाएगा। प्लेऑफ़ या फ़ाइनल में, यदि किसी कारण से सुपर ओवर या उसके बाद के सुपर ओवर पूरे नहीं हो पाते हैं, तो लीग में उच्च स्थान पर रहने वाली टीम को विजेता घोषित किया जाएगा।",
      ],
    },
    {
      header: "अंक वितरण",
      txt: [
        "प्रत्येक मैच जीतने पर 2 अंक दिए जाएंगे। किसी भी मैच में कोई नतीजा न निकलने या मैच टाई होने पर प्रति टीम एक अंक दिया जाएगा। किसी भी मैच में हार या ज़ब्त के लिए शून्य अंक।",
      ],
    },
  ];

  return (
    <>
      <section className="mearathon">
        <div className="container">
          <div className="d-flex justidy-content-center align-items-center w-100">
            <div className="bpl-text w-100">
              <div className="chairman-title d-flex align-items-center px-2 py-1 my-3">
                <h1
                  className="main-text-vicechairman-title mb-0"
                  style={{ lineHeight: "30px" }}
                >
                  Rauniyar Foundation Bharat(RFB) <br /> Bairgania Premier
                  League-Coming soon
                </h1>
              </div>
              <div className="bpl-dec my-3">
                <div className="d-flex  mb-3">
                  {/* <span>
                    <FaArrowRightLong className="me-2" />
                  </span> */}
                  <p className="list-table-one mb-0">
                    "When you play for the Bairgania Premier League, the jersey
                    doesn't just represent you, it represents the dreams of a
                    Bairgania people."-
                    <span className="list-table-one fw-semibold">
                      Vijay Kumar Gupta, Founder & Chairman of Rauniyar
                      Foundation Bharat.
                    </span>
                  </p>
                </div>
                <div className="half-merathone-list-title">
                  <p className="list-table-one">
                    रौनियार फाउंडेशन भारत - बैरगनिया प्रीमियर लीग 2024
                  </p>
                </div>
                <p className="list-table-one my-2">
                  बैरगनिया प्रीमियर लीग (बीपीएल) (भी प्रायोजन कारणों से Rauniyar
                  Foundation Bharat -BPL के रूप में जाना जाएगा ) पुरुषों और
                  महिलाओं का ट्वेंटी20 (टी20) क्रिकेट लीग जो साल 2024
                  बैरगनिया-भारत में आयोजित की जाएगी और 8 ग्राम पंचायत,26 वार्ड
                  ,69 सरकारी स्कूल ,25 प्राइवेट स्कूल तथा कॉलेज -आधारित टीमों
                  द्वारा खेला जाएगा। प्रतियोगिता इस साल नवम्बर और दिसंबर के बीच
                  सर्दियों में आयोजित की जाएगी।
                </p>
                <div className="d-flex  mb-3">
                  <span>
                    <FaArrowRightLong className="me-2" />
                  </span>
                  <p className="list-table-one mb-0">
                    BPL की 30 टीमों में से हर टीम दूसरी 15 टीमों के खिलाफ दो-दो
                    मैच और बाकी चार टीमों के खिलाफ एक-एक मैच खेलेगी।यानी कुल
                    35(30+4+1) मैच होंगे।
                  </p>
                </div>
                <div className="half-merathone-list-title">
                  <p className="list-table-one">नियम</p>
                </div>
                <div className="d-flex  mb-3">
                  <span>
                    <FaArrowRightLong className="me-2" />
                  </span>
                  <p className="list-table-one mb-0">
                    बीपीएल में कई नियम हैं जो स्थापित क्रिकेट के कानूनों या अन्य
                    ट्वेंटी20 लीग में उपयोग किए जाने वाले नियमों से भिन्न हैं:
                  </p>
                </div>
                <ul className="rule mt-4" style={{ listStyle: "disc" }}>
                  {bplRules.map((item, index) => {
                    return (
                      <>
                        <li key={index}>
                          <p className="list-table-one">{item}</p>
                        </li>
                      </>
                    );
                  })}
                </ul>
                <div className="half-merathone-list-title">
                  <p className="list-table-one">
                    पुरस्कार-बैरगनिया प्रीमियर लीग (बीपीएल)
                  </p>
                </div>
                <div className="mt-4">
                  {bplPrice.map((item, index) => {
                    return (
                      <>
                        <div className="half-merathone-list-title" key={index}>
                          <p className="list-table-one">{item.header}</p>
                        </div>
                        {item.txt.map((item, index) => {
                          return (
                            <p className="list-table-one" key={index}>
                              {item}
                            </p>
                          );
                        })}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <CricketTeamForm />
        </div>
      </section>
    </>
  );
};

export default BairganiyaCricketLeage;
