import { Tab } from "bootstrap";
import React from "react";
import { Tabs } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Fashion = () => {
  const fashionData = [
    {
      header: "ONE research page should showcase",
      list: [
        "Your identity and how that inspired your designs",
        "Reference images",
        "Colour palettes, patterns and fabric swatches",
        "100 - 200 words explaining your research into your identity",
      ],
    },
    {
      header: "ONE design page should include",
      list: [
        "Three fully designed outfits for any gender",
        "Make it as colourful and detailed as you want, with labels and notes",
        "Fabric and /or texture samples",
        "100 - 200 words to explain your outfit elements and details",
      ],
    },
  ];
  const fashionPrise = [
    {
      header: "1st Prize",
      txt: "Your winning design will be professionally made and showcased at RFB Fashion Week 24. On the final day, you will be presented with your cash/trophy/medal, a fashion starter pack, plus tickets for you, your teacher and your family to attend RFB24 to watch the show. Your design will also be on display throughout the four-day event in Bairgania.",
    },
    {
      header: "2nd Place Runner-Up Prize",
      txt: "Your design illustrations will be professionally printed and displayed at RFB Fashion Week in Bairgania. You’ll also receive a goodie bag and framed print of your work, plus tickets for you, your teacher and your family to attend RFB24 to watch a catwalk show on the final day, where your work will be digitally showcased.",
    },
    {
      header: "3rd Place Runner-Up Prize",
      txt: "Your design illustrations will be professionally printed and displayed at RFB Fashion Week in Bairgania. You’ll also receive a framed print of your work, plus tickets for you, your teacher and your family to attend RFB24 to watch a catwalk show on the final day, where your work will be digitally showcased.",
    },
  ];

  return (
    <>
      <section className="mearathon">
        <div className="container">
          <div className="row w-100 gx-3 gy-4">
            <div className="col-md-7">
              <div className="merathon-detail">
                <div className="register-dec">
                  <Tabs
                    defaultActiveKey="Regular"
                    id="uncontrolled-tab-example"
                    className="mb-3 d-none"
                  >
                    <Tab eventKey="Regular" className="tab-title">
                      <div className="main-text-mahostav">
                        <p
                          className={`regular-dec mahostav-dec main-text-chairman list-table-one `}
                        >
                          <p>
                            <div className="chairman-title d-flex align-items-center px-2 py-1 my-3">
                              <h1
                                className="main-text-vicechairman-title mb-0"
                                style={{ lineHeight: "30px" }}
                              >
                                Rauniyar Foundation Bharat(RFB) <br /> Fashion
                                Talent Hunt 2024
                              </h1>
                            </div>
                            Get ready for the Fusion of Singing and Fashion
                            Competition! "Super Star ki Khoj"
                            <br />
                            <br />
                            A five days’ workshop on the theme ‘Rauniyar
                            Foundation Bharat(RFB) Fashion Talent Hunt -2024’
                            will be conducted from 15th November 2024 to 25th
                            December 2024 in Bairgania.
                            <br />
                            <br />
                            During these five days event, around 20000 students
                            of schools and colleges from nearby areas of
                            Bairgania will be participating.
                            <span className="fw-semibold d-flex align-items-center gap-3">
                              <FaArrowRightLong className="me-2 my-4" />
                              Entry Fee:{" "}
                              <Link
                                to={"/bairganiaMahotsav"}
                                className="text-mahostav"
                              >
                                Entry Link
                              </Link>{" "}
                              <br />
                            </span>
                            <br />
                            The 20000 participants will be demonstrated about
                            the nuances of the fashion world, such as how to
                            create better styling with makeup as per theme
                            chosen by the fashion garments, fashion photography
                            technique, fabric selection according to the season,
                            how to move gracefully on the ramp and about the
                            current fashion trends by the participants.
                            <br />
                            <br />
                            The ‘Rauniyar Foundation Bharat(RFB) Fashion Talent
                            Hunt -2024’ workshop will help the students of
                            Bairgania to develop event organizing skill, and
                            also to promote, highlight, positioning and
                            showcasing the creative work among the invited
                            audience.
                            <br />
                            <br />
                            On the last day on-stage events such as dance,
                            singing and fashion show will be conducted. Top 5
                            contestants from male and female will be shortlisted
                            considering their score in the Retro day.
                            <br />
                            <br />
                            <span className="d-flex align-items-start gap-3">
                              <FaArrowRightLong className="demo-icon me-2 my-2" />
                              <p className="list-table-one">
                                1st Round: Talent Round – Students will be given
                                3 minutes time to show their talent in any form
                                like
                              </p>
                              <br />
                            </span>
                            <span className="d-flex align-items-start gap-3">
                              <FaArrowRightLong className="me-2 my-2" />
                              <p className="list-table-one">
                                2nd Round: Fashion Show – Q&A session
                              </p>
                              <br />
                            </span>
                            The event commenced with welcome dance by school &
                            college Student.
                            <br />
                            <br />
                            "Ability is of little account without opportunity,"
                            Napoleon said once. Perhaps, taking a cue from it,
                            the ‘Rauniyar Foundation Bharat(RFB)’ is organising{" "}
                            <span className="fw-semibold">
                              Fashion Talent Hunt -2024
                            </span>
                            ,to provide an opportunity to the would-be fashion
                            designers.
                            <br />
                            <br />
                            The{" "}
                            <span className="fw-semibold">
                              Fashion Talent Hunt -2024
                            </span>{" "}
                            is being organised with the prime objective of
                            encouraging young designers and promote fashion
                            education among the same.
                            <br />
                            <br />
                            The head, Programming and Creative Services,
                            <span className="fw-semibold">
                              {" "}
                              Rauniyar Foundation Bharat(RFB)
                            </span>
                            , Archana Gupta (Fashion Deginer) says, "This is the
                            first initiative of RFB in the field of education.
                            By organising this Fashion Talent Hunt we want to
                            encourage young designers to pursue fashion studies
                            as a viable career choice." To participate in the
                            ''Fashion Talent Hunt'', one needs to have a flair
                            for design, colour and element.
                            <br />
                            <br />
                            Calling all aspiring fashion designers! Want the
                            opportunity to design an outfit and see it come to
                            life?
                            <div className="half-merathone-list-title  d-flex align-items-center px-2 py-1 my-3 ms-2">
                              <h1 className="main-text-vicechairman-title mb-0 ms-0">
                                Who Can Apply?
                              </h1>
                            </div>
                            <span className="mahosatv-demo d-flex gap-3 align-items-center">
                              <span className="fw-semibold">
                                Open to Age Categories :
                              </span>
                              <p className="list-table-one m-0">
                                Year 7 - 9 | Year 10 - 11 | Year 12 - 13 or
                                College | House wife
                              </p>
                            </span>
                            <div className="half-merathone-list-title  d-flex align-items-center px-2 py-1 my-3 ms-2">
                              <h1 className="main-text-vicechairman-title mb-0 ms-0">
                                The Brief
                              </h1>
                            </div>
                            <p className="list-table-one">
                              All About Identity: Your identity is what makes
                              you unique. Design an outfit that celebrates your
                              identity, demonstrating how you have translated
                              these elements of yourself into a fashion creation
                              by fusing design ideas with self-reflection. You
                              might choose to explore your heritage, religion or
                              nationality. Maybe you’re inspired by your
                              physical or online community. Perhaps you want to
                              showcase an identity that you’ve created for
                              yourself. Express your identity through your
                              choice of materials, colours, prints, shapes and
                              textures. This is your time to get creative!
                            </p>
                            <div className="half-merathone-list-title  d-flex align-items-center px-2 py-1 my-3 ms-2">
                              <h1 className="main-text-vicechairman-title mb-0 ms-0">
                                The Work
                              </h1>
                            </div>
                            <p className="list-table-one">
                              Submit a two-page competition entry. It must
                              include one research page and one page showcasing
                              three fully designed outfits. These outfits can be
                              three separate looks around different areas of
                              your identity or a cohesive collection.
                            </p>
                            {fashionData.map((item, index) => {
                              return (
                                <>
                                  <div
                                    className="half-merathone-list-title  d-flex align-items-center px-2 py-1 my-3 ms-2"
                                    key={index}
                                  >
                                    <h1 className="main-text-vicechairman-title mb-0 ms-0">
                                      {item.header}
                                    </h1>
                                  </div>
                                  <ul
                                    className="my-3"
                                    style={{ listStyle: "disc" }}
                                  >
                                    {item.list.map((item, index) => {
                                      return (
                                        <>
                                          <li key={index}>
                                            <p
                                              className="list-table-one"
                                              style={{ marginLeft: "0px" }}
                                            >
                                              {item}
                                            </p>
                                          </li>
                                        </>
                                      );
                                    })}
                                  </ul>
                                </>
                              );
                            })}
                            {fashionPrise.map((item, index) => {
                              return (
                                <>
                                  <div
                                    className="half-merathone-list-title  d-flex align-items-center px-2 py-1 my-3 ms-2"
                                    key={index}
                                  >
                                    <h1 className="main-text-vicechairman-title mb-0 ms-0">
                                      {item.header}
                                    </h1>
                                  </div>
                                  <p className="list-table-one">{item.txt}</p>
                                </>
                              );
                            })}
                            <span className="mahosatv-demo d-flex gap-3 align-items-center mt-4">
                              <span className="fw-semibold">
                                Prize Per Age Category :
                              </span>
                              <p className="list-table-one m-0">
                                Year 7 - 9 | Year 10 - 11 | Year 12 - 13 or
                                College | House wife
                              </p>
                            </span>
                            <span className="d-flex gap-3 align-items-center mt-4 mb-2">
                              <span className="fw-semibold list-table-one">
                                Submission Deadline :
                              </span>
                              <p className="list-table-one m-0">
                                15 November 2024
                              </p>
                            </span>
                          </p>
                        </p>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-flex  align-items-center w-100 h-100 overflow-hidden">
                <div className="mahostav-video p-3 w-100">
                  <div className="video-mahostav overflow-hidden rounded-2">
                    <video
                      className="w-100 h-100 object-fit-cover"
                      poster={require("../../../assetes/img/BairganiaPNG.png")}
                      controls
                    >
                      <source
                        src={require("../../../assetes/video/fashion-mahostav.mov")}
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Fashion;
