import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import RegisterUser from "../RegisterUser";
import { FaArrowRightLong, FaUser } from "react-icons/fa6";
import "./header.css";

const Header = () => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(null);
  const [showCanvas, setShowCanvas] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(JSON.parse(localStorage.getItem("token")));
  });

  const showRegisterCanvas = () => {
    if (!token) {
      setShowCanvas(!showCanvas);
    }
  };

  const handleCloseCanvas = () => {
    setShowCanvas(false);
  };

  const handleRegistrationSuccess = () => {
    setShowCanvas(false);
    setToken(JSON.parse(localStorage.getItem("token")));
  };

  const closeHeader = () => {
    const navbarToggle = document.getElementById("navbarSupportedContent");
    if (navbarToggle.classList.contains("show")) {
      navbarToggle.classList.remove("show");
    }
  };

  return (
    <>
      <nav
        className="navbar navbar-sticky  navbar-expand-lg navbar-bg "
        id="demo"
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 W-100">
              <li className="nav-item">
                <Link className="menu-link fw-semibold d-block" to={"/"}>
                  Home
                </Link>
              </li>

              <li className="nav-item position-relative">
                <Link className="menu-link fw-semibold d-block">
                  About Us <FaChevronDown className="ms-1" />
                </Link>
                <ul className="abou-peta-menu">
                  <li className="about-menu">
                    <Link className="d-block fw-semibold" to={"/about"}>
                      About Rauniyar Foundation Bharat{" "}
                      <FaChevronDown className="ms-1" />
                    </Link>
                    <ul className="about-petaone-menu">
                      <li>
                        <Link
                          className="d-block fw-semibold"
                          to={"/chairmanmsg"}
                        >
                          Chairman Massage
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="d-block fw-semibold"
                          to={"/vicechairmanmsg"}
                        >
                          Vice Chairman Massage
                        </Link>
                      </li>
                      <li>
                        <Link className="d-block fw-semibold" to={"/moa"}>
                          Memorandum of Association (MOA)
                        </Link>
                      </li>
                      <li>
                        <Link className="d-block fw-semibold" to="/aoa">
                          Articles of Association (AOA)
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link className="menu-link fw-semibold d-block">
                  Philanthropy <FaChevronDown className="ms-1" />
                </Link>
                <ul className="philantropy-menu ">
                  <li>
                    <Link to={"/halfmerathon"} className="d-block fw-semibold">
                      Run4Unemployment Awareness
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/mahostavdashboard"}
                      className="d-block fw-semibold"
                    >
                      Bairgania Mahotsav <FaChevronDown className="ms-1" />
                    </Link>
                    <ul className="bairgnia-mahostav">
                      <li>
                        <a
                          href="https://www.facebook.com/profile.php?id=61551685960739"
                          target="_blank"
                          className="d-block fw-semibold"
                        >
                          2023-1st Editions
                        </a>
                      </li>
                      <li>
                        <Link
                          to={"/bairganiaMahotsav"}
                          className="d-block fw-semibold"
                        >
                          2024-2nd Editions <FaArrowRightLong /> Coming Soon
                        </Link>
                        <ul className="mahostav-peta-menu ps-0">
                          <li>
                            <Link
                              className="d-block fw-semibold"
                              to={"/photographymahostav"}
                            >
                              Photography Awards 2024
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="d-block fw-semibold"
                              to={"/paintingmahostav"}
                            >
                              Painting And Talent Hunt 2024
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="d-block fw-semibold"
                              to={"/fashionmahostav"}
                            >
                              Fashion Talent Hunt 2024
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="d-block fw-semibold"
                              to={"/foodandbeverage"}
                            >
                              Food and Beverage Trde Show
                            </Link>
                          </li>
                          <li>
                            <Link className="d-block fw-semibold" to={"/dance"}>
                              Dance Revolution Tournament 2024
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link className="d-block fw-semibold">
                      Cricket Premier League <FaChevronDown className="ms-1" />
                    </Link>
                    <ul className="cricket-leage">
                      <li>
                        <a href="/" className="fw-semibold d-block">
                          Bairgania Premier League{" "}
                        </a>
                      </li>

                      <li>
                        <Link to={"/bpl"} className="fw-semibold d-block">
                          2024-1st Editions <FaArrowRightLong /> Coming Soon
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link to={"/charity"} className="d-block fw-semibold">
                      Charity <FaChevronDown className="ms-1" />
                    </Link>
                    <ul className="chairity-menu">
                      <li>
                        <a
                          href="https://www.facebook.com/rauniyarfoundation/videos/744258243046793"
                          target="_blank"
                          className="d-block fw-semibold"
                        >
                          COVID-19
                        </a>
                      </li>

                      <li>
                        <Link
                          to={"/templedevelopment"}
                          className="d-block fw-semibold"
                        >
                          Temple Development & Re-Development{" "}
                          <FaChevronDown className="ms-1" />
                        </Link>
                        <ul className="maakalimandir">
                          {/* <li>
                            <a
                              href="https://www.facebook.com/profile.php?id=61551660973574"
                              target="_blank"
                              className="fw-semibold d-block"
                            >
                              Maa Kali Seva Samiti,Bairgania,Bharat{" "}
                            </a>
                          </li> */}
                          <li>
                            <a
                              href="https://www.facebook.com/profile.php?id=61565100176000"
                              target="_blank"
                              className="fw-semibold d-block"
                            >
                              Shri Ram Janki Math Nyaas Samiti,Bairgania,Bharat{" "}
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.facebook.com/profile.php?id=61560783137096&mibextid=LQQJ4d"
                              target="_blank"
                              className="fw-semibold d-block text-capitalize"
                            >
                              Shri Janki Janmabhoomi Punauradham Mandir Punaura
                              Math Punaura, Sitamarhi,Bharat
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className="fw-semibold d-block"
                      to={"/rfbflageprogram"}
                    >
                      RFB Bharat Flag program
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="fw-semibold d-block"
                      to={"/rfbharghartiranga"}
                    >
                      Har Ghar Tiranga
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link
                  className="menu-link fw-semibold d-block"
                  to={"/careears"}
                >
                  Careear
                </Link>
              </li>

              <li className="nav-item">
                <Link to={""} className="menu-link fw-semibold d-block">
                  Results <FaChevronDown className="ms-1" />
                </Link>
                <ul className="result-menu">
                  <li>
                    <a href="/" className="fw-semibold d-block">
                      2020
                    </a>
                  </li>
                  <li>
                    <a href="/" className="fw-semibold d-block">
                      2021
                    </a>
                  </li>
                  <li>
                    <a href="/" className="fw-semibold d-block">
                      2022
                    </a>
                  </li>
                  <li>
                    <a href="/" className="fw-semibold d-block">
                      2023
                    </a>
                  </li>
                  <li>
                    <a href="/" className="fw-semibold d-block">
                      2024
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link to={""} className="menu-link fw-semibold d-block">
                  News & Media <FaChevronDown className="ms-1" />
                </Link>
                <ul className="news-media">
                  <li>
                    <a
                      href="https://www.facebook.com/rauniyarfoundation/photos"
                      target="_blank"
                      className="d-block fw-semibold"
                    >
                      Image Gallery
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/rauniyarfoundation/videos"
                      target="_blank"
                      className="d-block fw-semibold"
                    >
                      Video Gallery
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/rauniyarfoundation"
                      target="_blank"
                      className="d-block fw-semibold"
                    >
                      News & PR
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className="menu-link fw-semibold d-block"
                  to={"/contactus"}
                >
                  Contac Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="menu-link fw-semibold d-block"
                  to=""
                  onClick={showRegisterCanvas}
                >
                  {token == null ? "Register" : <FaUser />}
                </Link>
                {token !== null && (
                  <ul className="logout-btn">
                    <li>
                      <Link
                        onClick={() => {
                          localStorage.removeItem("token");
                          navigate("/");
                        }}
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            </ul>

            <button
              type="button"
              className="btn-close d-lg-none border p-2"
              data-bs-dismiss="navbarSupportedContent"
              aria-label="Close"
              onClick={closeHeader}
            ></button>
          </div>
        </div>
      </nav>

      <div
        className={`offcanvas register-canvas offcanvas-end ${
          showCanvas ? "show" : ""
        } `}
        tabIndex="-1"
        id="offcanvasRightcanvas"
        aria-labelledby="offcanvasRightLabel"
      >
        {showCanvas == true ? (
          <RegisterUser
            onClose={handleCloseCanvas}
            onSuccess={handleRegistrationSuccess}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Header;
